import React from 'react'
import './SiteLoader.scss'

const SiteLoader = () => {
  return (
    <div className='loader-wrap'>
      <span className="loader"></span>
    </div>
  )
}

export default SiteLoader