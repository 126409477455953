import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface MenuItemType {
    path: string,
    title: string,
    sub_menu: MenuItemType[]
}

const initialState: MenuItemType[] = []

export const menuSlice = createSlice({
    name: 'menu',
    initialState,
    reducers: {
        setMenu: (state, action: PayloadAction<MenuItemType[]>) => {
            // console.log('setMenu', action.payload);
            // state.menu = action.payload
            state.length = 0
            for(let item of action.payload) {
                state.push(item)
            }
        }
    }
})

export const { setMenu } = menuSlice.actions

export default menuSlice.reducer