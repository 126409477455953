import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export type UserType = {
    "fio": string | null,
    "phone": string | null,
    "email": string,
    "type_activity": string | null,
    "inn": string | null,
    "okved": string | null
}

interface InitialStateType extends UserType {
    "isAuth": boolean | null,
    "token": string | null,
}

const initialState: InitialStateType  = {
    "isAuth": false,
    "token": null,
    "fio": null,
    "phone": null,
    "email": "",
    "type_activity": null,
    "inn": null,
    "okved": null
}

export const userSlice = createSlice({
    name: 'menu',
    initialState,
    reducers: {
        setUserInfo: (state, action: PayloadAction<UserType>) => {
            state.fio = action.payload.fio
            state.phone = action.payload.phone
            state.email = action.payload.email
            state.type_activity = action.payload.type_activity
            state.inn = action.payload.inn
            state.okved = action.payload.okved
        },
        setToken: (state, action: PayloadAction<string>) => {
            state.token = action.payload
        },
        setAuth: (state, action: PayloadAction<boolean>) => {
            state.isAuth = action.payload
        }
    }
})

export const { setUserInfo, setAuth, setToken } = userSlice.actions

export default userSlice.reducer